import type { Schemas } from "#shopware";

type LineItemProductParams = {
  id: string;
  quantity: number;
  type: "product";
};

interface ExtraCartOptions {
  triggerSideCart?: boolean;
}

const defaultExtraCartOptions: ExtraCartOptions = {
  triggerSideCart: true,
};

/**
 * Extended `useCartWithSideEffects` composable based on `useCart` to be able to trigger some side effects
 * @public
 * @category Cart & Checkout Side Effects
 */
export function useCartWithSideEffects() {
  const _useCart = useCart();
  const sideMenuController = useSideMenuModal();

  return {
    ..._useCart,
    addProduct: async (
      params: {
        id: string;
        quantity?: number;
      },
      extraCartOptions: ExtraCartOptions = defaultExtraCartOptions,
    ): Promise<Schemas["Cart"]> => {
      const _useCartAddProductResult = await _useCart.addProduct(params);
      if (extraCartOptions.triggerSideCart) {
        sideMenuController.open();
      }

      return _useCartAddProductResult;
    },
    addProducts: async (
      items: LineItemProductParams[],
      extraCartOptions: ExtraCartOptions = defaultExtraCartOptions,
    ) => {
      const _useCartAddProductsResult = await _useCart.addProducts(items);

      if (extraCartOptions?.triggerSideCart) {
        sideMenuController.open();
      }

      return _useCartAddProductsResult;
    },

    addProductWithOptions: async (
      params: {
        id: string;
        quantity?: number;
        options?: Record<string, string | number | boolean>;
      },
      extraCartOptions: ExtraCartOptions = defaultExtraCartOptions,
    ): ReturnType<(typeof _useCart)["addProductWithOptions"]> => {
      const _useCartAddProductWithOptionsResult =
        await _useCart.addProductWithOptions(params);

      if (extraCartOptions?.triggerSideCart) {
        sideMenuController.open();
      }

      return _useCartAddProductWithOptionsResult;
    },
  };
}
